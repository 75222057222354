// import axios from 'axios';
import React from 'react';
import { Formik } from "formik";
import { Icon } from "antd";
import * as Yup from 'yup';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Grid from "@material-ui/core/Grid";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PlaceIcon from "@material-ui/icons/Place";
import CallIcon from "@material-ui/icons/Call";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import { green } from '@material-ui/core/colors';
import { fade, makeStyles, createMuiTheme } from '@material-ui/core/styles';
import MySnackbarContentWrapper from '../../helpers/snackbar'
import axios from 'axios';

import { CSSTransition } from "react-transition-group";

import uuid from "uuid";
import { SFade, Slug } from '../../helpers/helpers'

import { BrowserView, MobileView, isMobile } from "react-device-detect";

import { FormHelperText } from '@material-ui/core';

// Custom Components
import SocialLinks from '../SocialLinks';


// Wrapper around react-springs Trail component.
// It will make each child (which must be a dom node) fade and trail in.

const subjectOptions = [
  {
    value: 'Sales Inquiries',
    label: 'Sales Inquiries',
  },
  {
    value: 'Help/Support',
    label: 'Help/Support',
  },
  {
    value: 'Partnerships',
    label: 'Partnerships',
  },
  {
    value: 'Press',
    label: 'Press',
  },
  {
    value: 'Other',
    label: 'Other',
  }
];

const ContactDetail = ({
  toggle,
  image,
  imageText,
  onClose,
  imageAddress,
}) => {
  
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackVariant, setSnackVariant] = React.useState('');
  const [snackMessage, setSnackMessage] = React.useState('');
  
  const contactValidation = Yup.object().shape({
    subject: Yup.string().required('Please enter Subject'),
    message: Yup.string().required('Please enter message'),
    name: Yup.string().required('Please enter name'),
    email: Yup.string().required('Please enter email'),
  });

  const useStylesReddit = makeStyles(theme => ({
    root: {
      border: "1px solid #e2e2e1",
      overflow: "hidden",
      borderRadius: 4,
      backgroundColor: "#fcfcfb",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:hover": {
        backgroundColor: "#fff"
      },
      "&$focused": {
        backgroundColor: "#fff",
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main
      }
    },
    focused: {}
  }));

  function handleSnackOpen() {
    setSnackOpen(true);
  }

  function handleSnackClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  }

  function RedditTextField(props) {
    const classes = useStylesReddit();

    return (
      <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
    );
  }
  const API_PATH = 'https://api.nceptio.com/process.php';
  let contactData = {
    name: '',
    subject: '',
    email: '',
    message: ''
  }

  // const theme = createMuiTheme({
  //   palette: {
  //     primary: green
  //   }
  // });

  return (
    <CSSTransition timeout={0} in={toggle} classNames="alert">
      <div className="cell">
        <SFade show={toggle}>
          <div className="details">
            <Slug delay={100}>
              <GridListTile>
                <div
                  style={{
                    backgroundImage: `url(${image})`,
                    width: "100vw",
                    height: "100vh",
                    display: "block",
                    backgroundSize: "cover",
                    backgroundPosition: "0 -30vh",
                    backgroundRepeat: "no-repeat"
                  }}
                />
                <div className="close">
                  <Icon
                    type="close"
                    style={{ cursor: "pointer" }}
                    onClick={onClose}
                  />
                </div>
                <div className="contact-detail-footer">
                  <Grid container justify="space-between" direction="row">
                    {/* HEADING */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ height: "auto" }}
                    >
                      <h1 className="text-center contact-heading">
                        {imageText}
                      </h1>
                    </Grid>

                    {/* CONTACT */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      lg={3}
                      className="footer-contact-detail pr-0"
                    >
                      {/* <div> */}
                      <div className="footer-detailed-contact footer-contact-detailed ">
                        <div className="footer-title">CONTACT</div>

                        <div className="footer-item">
                          <List>
                            <ListItem
                              button
                              component="a"
                              href="tel:+1 (646) 663-1122"
                            >
                              <ListItemIcon className="footer-detailed-contact-icon">
                                <CallIcon className="fill-white" />
                              </ListItemIcon>
                              <ListItemText
                                className="contact-list-font"
                                primary="Sales Inquiries"
                                secondary="+1 (646) 663-1122"
                              />
                            </ListItem>
                            <ListItem
                              button
                              component="a"
                              href="mailto:support@nceptio.com"
                            >
                              <ListItemIcon className="footer-detailed-contact-icon">
                                <ContactSupportIcon className="fill-white" />
                              </ListItemIcon>
                              <ListItemText
                                className="contact-list-font"
                                primary="Customer Support"
                                secondary="support@nceptio.com"
                              />
                            </ListItem>
                            <ListItem
                              button
                              component="a"
                              href="mailto:partner@nceptio.com"
                            >
                              <ListItemIcon className="footer-detailed-contact-icon">
                                <GroupAddIcon className="fill-white" />
                              </ListItemIcon>
                              <ListItemText
                                className="contact-list-font"
                                primary="Partnerships"
                                secondary="partner@nceptio.com"
                              />
                            </ListItem>
                            <ListItem button>
                              <ListItemIcon className="footer-detailed-contact-icon">
                                <PlaceIcon className="fill-white" />
                              </ListItemIcon>
                              <ListItemText
                                className="contact-list-font"
                                primary="Mailing Address"
                                secondary={imageAddress}
                              />
                            </ListItem>
                          </List>
                        </div>
                      </div>
                    </Grid>

                    {/* MESSAGE */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="footer-contact-detail"
                    >
                      <div className="footer-detailed-message footer-message-detailed">
                        <div className="footer-title">MESSAGE</div>
                        <div className="footer-item">
                          <form noValidate autoComplete="off">
                            <Grid container spacing={3}>
                              <Formik
                                 validationSchema={contactValidation}
                                initialValues={contactData}
                                onSubmit={values => {
                                  let data = {
                                    ...values,
                                    location: imageText
                                  }
                                  axios({
                                    method: 'POST',
                                    url: `${API_PATH}`,
                                    headers: { 'content-type': 'application/json' },
                                    data: data
                                  })
                                    .then(result => {
                                      setSnackVariant('success');
                                      setSnackMessage('Message Sent Successfully');
                                      handleSnackOpen()
                                    })
                                    .catch(error => {
                                      setSnackVariant('error');
                                      setSnackMessage('Please try again');
                                      handleSnackOpen()
                                    });
                                }
                                }>
                                {({ handleSubmit, values,errors, touched, setFieldValue }) => (
                                  <>
                                    <Grid item sm={6} xs={12}>
                                      <RedditTextField
                                        label="Full Name"
                                        margin="normal"
                                        variant="filled"
                                        name="name"
                                        id="reddit-fullname"
                                        fullWidth
                                        onChange={(e) => {
                                          setFieldValue('name', e.target.value)
                                        }}
                                      />
                                      {errors.name && touched.name && <FormHelperText error className="form-helper-text">{errors.name}</FormHelperText>}
                                      

                                      <RedditTextField
                                        label="Email"
                                        margin="normal"
                                        variant="filled"
                                        id="reddit-email"
                                        fullWidth
                                        type="email"
                                        onChange={(e) => {
                                          setFieldValue('email', e.target.value)
                                        }}
                                      />
                                      {errors.email && touched.email && <FormHelperText error className="form-helper-text">{errors.email}</FormHelperText>}
                                      <TextField
                                        label="Subject"
                                        select
                                        margin="normal"
                                        variant="filled"
                                        id="reddit-subject"
                                        fullWidth
                                        value={values.subject}
                                        onChange={(e) => {
                                          setFieldValue('subject', e.target.value)
                                        }}
                                      >
                                        {subjectOptions.map(option => (
                                          <MenuItem
                                            key={uuid.v1()}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      {errors.subject && touched.subject && <FormHelperText error className="form-helper-text">{errors.subject}</FormHelperText>}
                                    </Grid>
                                    <Grid
                                      item
                                      sm={6}
                                      xs={12}
                                      className="contact-detail-message"
                                    >
                                      <RedditTextField
                                        multiline
                                        rows="6"
                                        label="Message"
                                        margin="normal"
                                        variant="filled"
                                        id="reddit-email"
                                        fullWidth
                                        onChange={(e) => {
                                          setFieldValue('message', e.target.value)
                                        }}
                                      />
                                      {errors.message && touched.message && <FormHelperText error className="form-helper-text">{errors.message}</FormHelperText>}
                                        <Fab
                                          variant="extended"
                                          color="default"
                                          aria-label="Add"
                                          className="btn-send-message button contact-detail-message-btn"
                                          onClick={handleSubmit}
                                        >
                                          <MobileView>
                                          Send
                                          </MobileView>
                                          <BrowserView>
                                          Send Message
                                          </BrowserView>
                                          &nbsp; <SendIcon />
                                        </Fab>
                                      <Snackbar
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                          }}
                                          open={snackOpen}
                                          autoHideDuration={5000}
                                          onClose={handleSnackClose}
                                          className="contact-snackbar"
                                        >
                                          <MySnackbarContentWrapper
                                            onClose={handleSnackClose}
                                            variant={snackVariant}
                                            message={snackMessage}
                                          />
                                        </Snackbar>
                                    </Grid>
                                  </>
                                )}
                              </Formik>
                            </Grid>
                          </form>
                        </div>
                      </div>
                    </Grid>

                    {/* SOCIAL ICONS */}
                    <SocialLinks />

                    {/* HORIZONTAL LINE */}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="footer-item footer-contact-detail-border"
                    />

                    {/* BOTTOM FOOTER TEXT - LEFT */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      align="left"
                      className="footer-contact-detail contact-bottom-footer footer-bottom-grid-left"
                    >
                      <div className="footer-text subfooter-col1 contact-subfooter-text-1">
                        {/* <span className=""> */}
                        <div className="vlr">
                          <img
                            className="footer-logo footer-logo-img "
                            src="./image/nceptio_logo2.png"
                          />
                        </div>
                        {/* </span> */}
                        <wbr />
                        <span className="padding-10">
                          Taking the spark of an idea and turning it into
                          reality™
                        </span>
                      </div>
                    </Grid>

                    {/* BOTTOM FOOTER TEXT - RIGHT */}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      align="left"
                      className="footer-contact-detail contact-bottom-footer footer-bottom-grid-right"
                    >
                      <div className="footer-text subfooter-col2 contact-subfooter-text-2">
                        <span className="vlr">
                          &#169; 2015-2019 NCEPTIO, LLC. All Rights Reserved
                        </span>
                        <span className="contact-detail-footer-link detail-footer-link">
                          <a className="footer-link pr-10" href="#">
                            <Button size="small">Privacy Policy</Button>
                          </a>

                          <a className="footer-link" href="#">
                            <Button size="small">Terms of service</Button>
                          </a>
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </GridListTile>
            </Slug>

          </div>

        </SFade>
      </div>
    </CSSTransition>
  );
};

export default ContactDetail;