import React from "react";
import { makeStyles, createStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import "./Footer.scss";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ChevronRightCircleOutlineIcon from 'mdi-react/ChevronRightCircleOutlineIcon';
import ChatBubble from 'mdi-react/ChatBubbleIcon';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Fade from 'react-reveal/Fade';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from "react-router-dom";

// Custom Components
import SocialLinks from "../SocialLinks";

const useStyles = makeStyles((theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  })
);

export default function Footer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }
  return (
    <div className="footer footer-animation">
      <div className="footer-main" ref={props.refFooter}>
        <div className="footer-button">
          <Fade up delay={1000}>
            <div className="footer-open-button bg-icon-chat footer-open-close-button">
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="Show more"
              >
                <ExpandLessIcon className="icon-color" />
              </IconButton>
            </div>
          </Fade>
          <div className="button-div">
            <Fade up delay={1000}>
              <Button className="button-hire landscpe-button-hire">
                HIRE NCEPTIO TALENT
                </Button>

              <Button className="button-freelance landscpe-button-freelance">
                FREELANCE WITH US
                </Button>
            </Fade>
          </div>
          <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} aria-labelledby="form-dialog-title" className="button-pop-up">
            <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address here. We will send updates
                occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleClose} color="primary">
                Subscribe
              </Button>
            </DialogActions>
          </Dialog>
          <Fade up delay={1000}>
            <div className="footer-chatbutton bg-icon-chat" >
              <IconButton><ChatBubble className={`icon-color ${classes.button}`} /></IconButton>
            </div>
          </Fade>
        </div>
        {/* /////////////// */}
        <div className="footer-mobile">
          <div className="footer-open-button-mobile">
            <div className="bg-icon">
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="Show more"
              >
                <ExpandLessIcon className="icon-color" />
              </IconButton>
            </div>
            <div className="bg-icon-chat">
              <IconButton><ChatBubble className="icon-color" /></IconButton>
            </div>
          </div>
          <div className="button-div-mobile">
            <Button className={props.modalState ? "button-hire button-left-footer" : "button-hire"}>
              HIRE TALENT
                </Button>
            <Button className={props.modalState ? "button-freelance button-right-footer" : "button-freelance"}>
              FREELANCE
                </Button>
          </div>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className="footer-content footer-content-mobile-landscape">
            <div className="content content-mobile-landscape">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={3} lg={3} align="center">
                  <div className="footer-contact">
                    <div className="footer-title">CONTACT</div>
                    <div className="footer-item">
                      <List component="nav">
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <Link to="/contact" className="footer-link">
                            <div className="small-font-mobile">
                              Contact Us
                          </div>
                          </Link>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile">
                            Press
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile">
                            FAQ
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} align="center">
                  <div className="footer-about">
                    <div className="footer-title">ABOUT</div>
                    <div className="footer-item">
                      <List component="nav">
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <Link to="/about" className="footer-link">
                            <div className="small-font-mobile">
                              About Us
                              </div>
                          </Link>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile">
                            Our Process
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile"> Our
                          Groups
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />
                          <div className="small-font-mobile"> Our
                          Products
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3} align="center">
                  <div className="footer-talent">
                    <div className="footer-title">TALENT</div>
                    <div className="footer-item">
                      <List component="nav">
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                            Freelance Designers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                            Freelance Developers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                            Freelance Finance Experts
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                            Freelance Product Managers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                            Freelance Project Managers
                          </div>
                        </ListItem>
                        <ListItem button>
                          <ChevronRightCircleOutlineIcon className="icon" />{" "}
                          <div className="small-font-mobile">
                            Specialized Talent
                          </div>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                </Grid>

                {/* SOCIAL ICONS */}

                <SocialLinks />

                {/* HORIZONTAL LINE */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="footer-item footer-contact-detail-border"
                />

                {/* BOTTOM FOOTER TEXT - LEFT */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  align="left"
                  className="footer-contact-detail contact-bottom-footer footer-bottom-grid-left"
                >
                  <div className="footer-text subfooter-col1">
                    <div className="vlr">
                      <img
                        className="footer-logo footer-logo-img "
                        src="./image/nceptio_logo2.png"
                      />
                    </div>
                    <wbr />
                    <span className="padding-10">
                      Taking the spark of an idea and
                      turning it into reality™
                      </span>
                  </div>
                </Grid>

                {/* BOTTOM FOOTER TEXT - RIGHT */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  align="left"
                  className="footer-contact-detail contact-bottom-footer footer-bottom-grid-right"
                >
                  <div className="footer-text subfooter-col2 common-subfooter">
                    <span className="vlr">
                      &#169; 2015-2019 NCEPTIO, LLC. All Rights Reserved
                      </span>
                    <span className=" detail-footer-link">

                      <a className="footer-link pr-10" href="#">
                        <Button size="small">
                          <div className="small-font-mobile">
                            Privacy Policy
                          </div>

                        </Button>
                      </a>

                      <a className="footer-link" href="#">
                        <Button size="small">
                          <div className="small-font-mobile">
                            Terms of service
                          </div>

                        </Button>
                      </a>
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}