import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import posed, { PoseGroup } from 'react-pose';
import Home from "./components/home/Home";
import aboutus from "./components/aboutus/aboutus";
import work from "./components/work/work";
import contact from "./components/contact/contact";
import services from "./components/services/services";
import team from "./components/team/team";
import intro from "./components/intro/intro";
import blog from "./components/blog/blog"
import "./App.css";
import Div100vh from 'react-div-100vh';


const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 500, staggerChildren: 50  },
  exit: { opacity: 0, staggerChildren: 20, staggerDirection: -1 }
});

// const RouteContainer = posed.div({
//   enter: {
//     opacity: 1,
//     delay: 300,
//     beforeChildren: true
//   }
// });

const CustomApp = () => (
  <Route
    render={({ location }) => (
      <div id="site-container">              
        <div id="content-container">
          <PoseGroup>
            <RouteContainer key={location.pathname}>
              <Switch location={location}>
                <Route path="/" component={Home} exact  key="index" />
                <Route path="/about" component={aboutus}  key="about" />
                <Route path="/work" component={work}  key="work" />
                <Route path="/contact" component={contact}  key="contact" />
                <Route path="/services" component={services}  key="services" />
                <Route path="/team" component={team}  key="team" />
                <Route path="/intro" component={intro}  key="intro" />
                <Route path="/blog" component={blog}  key="blog" />
              </Switch>
            </RouteContainer>
          </PoseGroup>
        </div>
      </div>
    )}
  />
);

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <CustomApp />
      </BrowserRouter>
    );
  }
}
export default App;
