import React from "react";
import AOS from "aos";
import posed from 'react-pose';
import "react-id-swiper/src/styles/scss/swiper.scss";
import "react-animated-slider/build/vertical.css";
import "animate.css/animate.min.css";
import Fade from "react-reveal/Fade";
import ScrollSnap from "scroll-snap";
import uuid from "uuid";

import SideMenu from '../SideMenu/SideMenu';
import "./aboutus.scss";
import Header from "../header/Header";
import Footer from "../Footer/Footer";
import Div100vh from 'react-div-100vh';


const snapConfig = {
  scrollSnapDestination: "0% 100vh",
  scrollTimeout: 300,
  scrollTime: 200
};

const Container = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: 0, opacity: 1 }
});

class Aboutus extends React.Component {
  container = React.createRef();

  constructor(props, context) {
    super(props, context);
    AOS.init();
    this.myRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      approach1: ``,
      activeIndex: 0
    };
    this.menuData = [
      {
        path: "about"
      },
      {
        path: "approach-people"
      },
      {
        path: "approach-client"
      },
      {
        path: "mission"
      },
      {
        path: "principles"
      }
    ];
  }

  componentDidMount() {
    this.bindScrollSnap();
  }

  bindScrollSnap() {
    const callback = () => {
      const activeIndex = Math.round(
        this.container.current.scrollTop / this.container.current.offsetHeight
      );

      this.setState({ activeIndex });
    };
    const element = this.container.current;
    const snapObject = new ScrollSnap(element, snapConfig);
    snapObject.bind(callback);
  }

  onScroll = () => {
    const scrollTop = this.myRef.current.scrollTop;
    this.setState({
      scrollTop: scrollTop
    });
  }

  pagination = index => {
    this.setState({ activeIndex: index });
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }))
  }

  render() {
    const {history} = this.props;
    const { activeIndex, isMenuOpen } = this.state;
    const { pathname } = this.props.location;
    const { overflowHidden } = {
      overflow: "hidden"
    };

    return (
      <Container>
        <SideMenu isOpen={isMenuOpen} toggleMenu={this.toggleMenu} pathname={pathname} history={this.props.history}/>
        <div className="container" ref={this.container} style={overflowHidden}>
          <Header isOpen={isMenuOpen} title1="ABOUT" title2="US" page={pathname} toggleMenu={this.toggleMenu} />
          <div
            className="aboutus-content scroll"
            ref={this.myRef}
            onScroll={this.onScroll}
            id="scroll"
          >
            <Div100vh className="paginateWrapper">
              <ul >
                {this.menuData &&
                  this.menuData.map((menuItem, index) => {
                    return (
                      <li
                        key={uuid.v1()}
                        className={index === activeIndex ? "active" : ""}
                        onClick={() => this.pagination(index)}
                      >
                        <a href={`#${menuItem.path}`} />
                      </li>
                    );
                  })}
              </ul>
            </Div100vh>
            <Div100vh className="intro-parent-section v-center-flex about-div" id="about">
              <Fade delay={2000}>
                <div className="intro-section">
                  <h1 className="intro-title">
                    <a href="#">We Are</a>
                  </h1>
                </div>
              </Fade>
              <div className="we-are-text about__text-desc about-sub-text height-100 about-desc__text">
                <Fade delay={3000}>
                  <h2 className="nceptio-text">NCEPTIO</h2>
                </Fade>
                <Fade delay={4500}>
                  <h2>
                    we are <b>thought leaders</b>.
                </h2>
                </Fade>
                <Fade delay={6000}>
                  <h2>
                    we <b>design</b> and <b>manage products</b>
                  </h2>
                </Fade>
                <Fade delay={6000}>
                  <h2>
                    from <b>inception</b> to <b>launch</b>
                  </h2>
                </Fade>
                <Fade delay={7500}>
                  <h2>
                    and <b>beyond...</b>
                  </h2>
                </Fade>
              </div>
            </Div100vh>
            <Div100vh className="intro-parent-section v-center-flex about-div" id="approach-people">
              <Fade delay={1500}>
                <div className="intro-section" data-aos="fade-zoom-in" data-aos-delay="1500">
                  <h1 className="intro-title">Approach</h1>
                </div>
              </Fade>
              <div className="we-are-text about__text-desc about-sub-text height-100 about-desc__text">
                <Fade delay={3000}>
                  <h2 id="Approach-text1">
                    we <b>focus</b> on <b>people</b>
                  </h2>
                </Fade>
                <Fade delay={4500}>
                  <h2>
                    {" "}
                    nurturing <b>relationships</b>
                  </h2>
                </Fade>
                <Fade delay={6000}>
                  <h2>
                    earning <b> trust</b>
                  </h2>
                </Fade>
                <Fade delay={7500}>
                  <h2>
                    and <b>doing</b> things <b>right</b>
                  </h2>
                </Fade>
                <Fade delay={9000}>
                  <h2>
                    --<b> not </b>our <b>bottom line.</b>
                  </h2>
                </Fade>
              </div>
            </Div100vh>
            <Div100vh className="intro-parent-section v-center-flex about-div" id="approach-client">
              <Fade delay={1500}>
                <div className="intro-section">
                  <h1 className="intro-title">Goal</h1>
                </div>
              </Fade>
              <div className="we-are-text about__text-desc about-sub-text height-100 about-desc__text">
                <Fade delay={3000}>
                  <h2>
                    <b>inspire</b> our <b>clients</b> to:
                </h2>
                </Fade>
                <Fade delay={4500}>
                  <h2>
                    <b>exceed </b>their <b>limits</b>
                  </h2>
                </Fade>
                <Fade delay={6000}>
                  <h2>
                    <b>innovate</b> and <b>disrupt </b>
                  </h2>
                </Fade>
                <Fade delay={8000}>
                  <h2>
                    <b> challenge </b>the <b>status quo.</b>
                  </h2>
                </Fade>
              </div>
            </Div100vh>
            <Div100vh className="intro-parent-section v-center-flex about-div" id="mission">
              <Fade delay={1500}>
                <div className="intro-section">
                  <h1 className="intro-title">Mission</h1>
                </div>
              </Fade>
              <div className="we-are-text about__text-desc about-sub-text about-desc__text">
                <Fade delay={3000}>
                  <h2>
                    <b>provide clients </b>with
                </h2>
                </Fade>
                <Fade delay={3000}>
                  <h2>
                    <b>valuable, cost-controlled results</b>
                  </h2>
                </Fade>
                <Fade delay={3000}>
                  <h2>
                    that will <b>transform</b> their <b>vision </b>
                  </h2>
                </Fade>
                <Fade delay={3000}>
                  <h2>
                    <b>into reality.</b>
                  </h2>
                </Fade>
              </div>
            </Div100vh>
            <Div100vh className="intro-parent-section v-center-flex about-div" id="principles"
             style={{
              minHeight: "-webkit-fill-available",
              minHeight: "100%"
            }}
            >
              <Fade delay={1500}>
                <div className="intro-section">
                  <h1 className="intro-title">Principles</h1>
                </div>
              </Fade>
              <div className="we-are-text about__text-desc about-sub-text about-desc__text"
              >
                <Fade delay={3000}>
                  <h2>
                    we <b> believe in:</b>
                  </h2>
                </Fade>
                <Fade delay={4500}>
                  <h2>
                    <b>hard work</b>
                  </h2>
                </Fade>

                <Fade delay={6000}>
                  <h2>
                    <b>dedication </b>to <b>our team </b> and <b>clients</b>
                  </h2>
                </Fade>
                <Fade delay={7500}>
                  <h2>
                    <b>adding value </b>to anything we touch
                </h2>
                </Fade>
                <Fade delay={9000}>
                  <h2>
                    if we're in,<b>we're in all the way!</b>
                  </h2>
                </Fade>
              </div>
            </Div100vh>
            <Footer />
          </div>
        </div>
      </Container>
    );
  }
}
export default Aboutus;
