export const menuData = [
  {
    path: "about"
  },
  {
    path: "why1"
  },
  {
    path: "who"
  },
  {
    path: "core1"
  },
  {
    path: "core2"
  },
  {
    path: "core3"
  },
];

export const slides = [
  {
    id: 0,
    title: "Developers",
    url: "./image/developer.svg",
    icon: "./image/mouse.svg"
  },
  {
    id: 1,
    title: "Designers",
    url: "./image/designer.svg",
    icon: "./image/pencil.svg"
  },
  {
    id: 2,
    title: "Project Manager",
    url: "./image/project_manager.svg",
    icon: "./image/folder.svg"
  },
  {
    id: 3,
    title: "Data Scientist",
    url: "./image/Data_Scientist.svg",
    icon: "./image/graph.svg"
  },
  {
    id: 4,
    title: "Other Experts",
    url: "./image/nceptio-other-experts-group.svg",
    icon: "./image/question_mark.svg"
  }
];
  