import React from 'react';
import Swiper from "react-id-swiper/lib/ReactIdSwiper.full";
import { Pagination } from "swiper/dist/js/swiper.esm";

const params = {
  shouldSwiperUpdate: true,
  activeSlideKey: "1",
  loadPrevNext: false,
  modules: [Pagination],
  direction: "horizontal",
  mousewheel: true,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true
  }
};

export const getChaseSlide = () => {
    return (
        <Swiper {...params}>
            <div>
                <p className="slider-content purple-text">
                    I live my life by one <b>core principle:</b>
                </p>
                <p className="slider-content raleway-semibold">
                    <span>
                        "Never look up to anyone: Never look down to anyone: Always look 'em
                        right in the eye."
                    </span>
                </p>
                <p className="slider-content purple-text">
                    - Ed Stanley (my grandfather)
        </p>
            </div>
            <div>
                <p className="slider-content">
                    <b>Motivation</b>
                </p>
                <p className="slider-content purple-text">
                    I've never met a stranger; I love getting to know people. And helping
                    others to achieve their goals is my personal mission.
                </p>
            </div>
        </Swiper>
    );
}

export function getAreasSlide() {
    return (
        <Swiper {...params}>
            <div>
                <p className="slider-content raleway-semibold">
                    <span>
                        "Being behind the lens, you have to master light and all the
                        technicalities that come with shooting something. Even more
                        important, is being able to connect with your subject so they give
                        you themselves, without any mask on. In photography and film, that
                        is my talent.."
                     </span>
                </p>
            </div>
            <div>
                <p className="slider-content">
                    <b> Motivation </b>
                </p>
                <p className="slider-content purple-text">
                    I always try to bring out the very best in people.
                </p>
            </div>
        </Swiper>
    );
}

export function getMaySlide() {
    return (
        <Swiper {...params}>
            <div>
                <p className="slider-content raleway-semibold">
                    <span>
                        "Losing my cousin at a very early age significantly Influenced my
                        desire to pursue a career in medicine."
                    </span>
                </p>

            </div>
            <div>
                <p className="slider-content">
                    <b> Motivation </b>
                </p>
                <p className="slider-content purple-text">
                    Using my knowledge of medicine to help people heal themselves is
                    extremely important to me. Combining that with innovation and
                    technology is paramount to that mission
                </p>
            </div>
        </Swiper>
    );
}
