import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVert from "@material-ui/icons/MoreVert";

import "./Header.css";

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div className="header" ref={props.refFunction}>
      <div className="header-logo">
        <div className="header-image" onClick={() => props.toggleMenu()}>
          <a className="mobile-logo">
            {props.isOpen ?
              <img src="./image/NCEPTIO-ICON-WHITE.svg" alt="logo" className={props.isOpen ? "slide-logo-right logo-height" : " slide-logo-left logo-height"} />
              :
              <img src="./image/NCEPTIO-ICON-WHITE.svg" alt="logo" className={props.modalState ? "button-left logo-height" : " fade-in logo-height"} />
            }

            <span className={!props.isOpen ? " " : "logo-text tracking-in-expand"} >NCEPTIO</span>

          </a>
          <a className="web-logo">
            {props.isOpen ?
              <img src="./image/NCEPTIO-ICON-WHITE.svg" alt="logo" className="logo-web-height" />
              :
              <img src="./image/NCEPTIO-ICON-WHITE.svg" alt="logo" className="logo-web-height" />
            }
            {/* <Fade left> */}

            <span className={!props.isOpen ? " " : "logo-text tracking-in-expand"} >NCEPTIO</span>

            {/* </Fade> */}
          </a>
        </div>
        <div className="btn-login-div">
          {props.isOpen ?
            <Button className={`${props.isOpen ? "slide-login-left" : "slide-logo-right"} `}>LOGIN</Button>
            :
            <Button className={`${props.modalState ? "login-btn button-right" : " login-btn"} `}>LOGIN</Button>
          }

        </div>

        <div className="mobile-login-div">
          <div className="header-right-mobile contact-heder-right-icon">
            {props.isOpen ?
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="slide-login-left"
              >
                <MoreVert className="mobile-more-vert" onClick={handleClick} />
              </Button>
              :
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={props.modalState ? "button-right" : " "}
              >
                <MoreVert className="mobile-more-vert" onClick={handleClick} />
              </Button>
            }

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}><span className="mobile-login-button">Login</span></MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      {props.page ? (
        <div className={props.modalState ? "aboutus-title top-title bottom-up" : "aboutus-title top-title"}>
          <p className="title-text">{props.title1}</p>
          <p className="title-text">{props.title2}</p>
        </div>
      ) : (
          <div className="aboutus-title top-title mt-0">
            <p className="title-text">{props.title1}</p>
            <p className="title-text">{props.title2}</p>
          </div>
        )}
    </div>
  );
}
