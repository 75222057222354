import React from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Div100vh from "react-div-100vh";

export default function WhyService(props) {
    return (
        <Div100vh
            className="why1 scroll-item v-center-flex intro-parent-section"
            id="why1"
        >
            <Fade delay={2000}>
                <div className="intro-section">
                    <h1 className="intro-title">Why</h1>
                </div>
            </Fade>
            <div className="we-are-text about__text-desc service__text-desc about-sub-text service-sub-text-justify-evenly service__text-desc-margin">
                <div className="about-sub-text ">
                    <Fade delay={3000} duration={3000}>
                        <h2 id="Approach-text1">
                            we <b>understand business</b>
                        </h2>
                    </Fade>
                    <Fade delay={3000} duration={3000}>
                        <h2>
                            and <b>help you succeed</b> by
                        </h2>
                    </Fade>
                    <Fade delay={3000} duration={3000}>
                        <h2>
                            <b> providing </b> only <b> what you need </b>
                        </h2>
                    </Fade>
                </div>

                {props.svgChanged ? (
                    <div className="service__text-img service-why__text-img">
                        <div className="service__text-img-1 service-img">
                            <div className="h-70">
                                <img
                                    className="slide-right-new what-bg-img"
                                    src="./image/the-other-guys-group-money-sign.svg"
                                />
                            </div>
                            <Fade delay={7000} duration={6000}>
                                <h6 className="font-mobile font-relative">
                                    THE OTHER GUYS
                                </h6>
                            </Fade>
                        </div>
                        <div className="service__text-img-horizontal-line tracking-out-contract-bck-new" />

                        <Zoom delay={9850}>
                            <img
                                className="down-arrow"
                                src="./image/down-arrow-1.svg"
                            />
                        </Zoom>

                        <div className="service__text-img-2 service-img">
                            <Fade right delay={6000}>
                                <div className="h-70 main-div-overlape-position">
                                    <img
                                        className="slide-left-new what-bg-img what-bg-img-width why-img1-overlap"
                                        src="./image/nceptio-hero-plain.svg"
                                    />
                                    <img
                                        className="slide-left-new what-bg-img what-bg-img-width why-img2-overlap"
                                        src="./image/nceptio-hero-plain.svg"
                                    />
                                </div>
                            </Fade>
                            <Fade delay={7000} duration={6000}>
                                <h6 className="font-mobile font-relative">NCEPTIO</h6>
                            </Fade>
                        </div>
                    </div>
                ) : (
                        <div />
                    )}
            </div>
        </Div100vh>
    )
}
